import React, { useEffect, useState } from 'react';
import { Box, Flex, Grid, GridItem, Heading, Text, useToast } from '@chakra-ui/react';
import { Sidebar } from '../Sidebar';
import { usePresentation } from '../../hooks/usePresentation.ts';
import { BasicSlideT } from '../../../../types/Presentation.ts';
import find from 'lodash/find';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { Element } from '../../../commons/components/Element';
import { Card } from '../../../commons/components/Card';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddNewSlide } from '../AddNewSlide';
import { TopNavigation } from '../../../commons/components/TopNavigation';
import { openPresentationInClientApp } from '../../service/LinkService.ts';
import { ElementPreview } from '../ElementPreview';
import { Loading } from '../../../commons/components/Loading';
import { translate } from '../../../../translate';

interface Props {
	presentationId: string;
	slideId?: string;
}

export const PresentationDetails: React.FC<Props> = ({ presentationId, slideId }) => {
	const { isLoading, isRefetching, data: presentation } = usePresentation(presentationId);

	const toast = useToast();

	const navigate = useNavigate();

	const { pathname } = useLocation();

	const [selectedSlide, setSelectedSlide] = useState<BasicSlideT>();

	//Update state when data arrives
	useEffect(() => {
		if (!isLoading && !isRefetching && presentation) {
			if (slideId) {
				//If slideId then a slide is selected in the url
				setSelectedSlide(find(presentation?.slides, { id: slideId }));
			} else {
				//If not then is the root of the presentation, select the first slide
				setSelectedSlide(first(presentation?.slides));
			}
		}
	}, [presentation, slideId, pathname, isLoading, isRefetching]);

	const handleSelectSlide = (slide: BasicSlideT) => {
		navigate(`/presentations/${presentationId}/slides/${slide.id}`);
	};

	const handleOnviewPresentationInFrontend = () => {
		openPresentationInClientApp(presentation?.id);
	};

	if (isLoading) {
		return <Loading />;
	}

	if (!isLoading && isEmpty(presentation)) {
		navigate(`/presentations`);
		const id = 'presentationNotFound';
		if (!toast.isActive(id)) {
			toast({
				id: id,
				title: 'Presentations dont exists',
				description: '',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top',
			});
		}
		return null;
	}

	return (
		<Grid
			templateAreas={`"header header header"
				"nav main configuration"`}
			templateColumns={'1fr 6fr 4fr'}
			templateRows='1fr'
			mx={0}
			gap={0}
			maxH='full'
		>
			<GridItem area='header' mb={2}>
				<TopNavigation presentationId={presentation!.id} presentationTitle={presentation?.title} onViewPresentationInFrontend={handleOnviewPresentationInFrontend} />
			</GridItem>
			<GridItem height='full' maxH='full' area='nav' as={Flex} flexDirection='column' alignItems='center' mx={4}>
				<Box>
					{/* Suggest creating a new slide if the presentation has no slides and the path does not contain new */}
					<AddNewSlide /*defaultIsOpen={isEmpty(presentation?.slides) && !includes(pathname, 'new')}*/ />
				</Box>
				<Sidebar presentationId={presentation!.id} slides={presentation!.slides} selectedSlide={selectedSlide} onSelectSlide={handleSelectSlide} />
			</GridItem>
			<GridItem
				h='88.5vh'
				maxH='88.5vh'
				overflowY='scroll'
				as={Card}
				area='main'
				mr={4}
				bg='white'
				__css={{
					scrollbarWidth: 'none',
					'&::-webkit-scrollbar': {
						display: 'none',
					},
				}}
			>
				{!isEmpty(selectedSlide) ? <ElementPreview presentation={presentation} slide={selectedSlide} /> : null}
				{isEmpty(presentation?.slides) ? (
					<Flex w='100%' p={8} flexDirection='column' alignItems='center'>
						<Heading>{translate('Start your presentation')}</Heading>
						<Text fontSize='lg'>{translate('Click New Slide to begin')}</Text>
					</Flex>
				) : null}
			</GridItem>
			<GridItem
				p={8}
				mr={4}
				h='88.5vh'
				maxH='88.5vh'
				area='configuration'
				as={Card}
				bg='white'
				flexDirection='column'
				alignItems='center'
				overflowY='scroll'
				__css={{
					scrollbarWidth: 'none',
					'&::-webkit-scrollbar': {
						display: 'none',
					},
				}}
			>
				{!isEmpty(selectedSlide) ? (
					<Flex w='full' flexDirection='column'>
						<Element presentationId={presentationId} element={selectedSlide?.element} />
					</Flex>
				) : null}
			</GridItem>
		</Grid>
	);
};
